import React from 'react';
import { FiTwitter, FiInstagram  , FiMap} from 'react-icons/fi';
import { FaTiktok } from 'react-icons/fa';

// import { FooterOverlay, Newsletter } from '../../components';
import { images } from '../../constants';
import './Footer.css';
import { IoLocationOutline, IoPhonePortrait, IoTimeOutline} from 'react-icons/io5';


const Footer = () => (
  <div className="app__footer section__padding" id="login">
    {/* <FooterOverlay /> */}
    {/* <Newsletter /> */}

    <div className="app__footer-links">
      <div className="app__footer-links_contact">
        <h1 className="app__footer-headtext">Contact Us</h1>
        <p className="p__opensans"><IoLocationOutline /> Binaa Complex, Karen, Nairobi, KENYA</p>
        <p className="p__opensans"><IoPhonePortrait /> +254 7035 90989</p>
        {/* <p className="p__opensans"><IoPhonePortrait /> +254 7555 1230</p> */}
      </div>

      <div className="app__footer-links_logo">
        <img src={images.Image} alt="footer_logo" />
        <p className="p__opensans">&quot;The best way to find yourself is to lose yourself in the service of others.&quot;</p>
        <img src={images.spoon} className="spoon__img" style={{ marginTop: 15 }} />
        <div className="app__footer-links_icons">
        <a href="https://www.tiktok.com/@cafecassia_ke?_t=8mySpn9pMs1&_r=1"><FaTiktok /></a>
        <a href="#"><FiTwitter /></a>
        <a href="https://www.instagram.com/cafecassia_ke/"><FiInstagram /></a>
        <a href="https://maps.app.goo.gl/BegTBD2Zuk2ExAbQA"><FiMap /></a>
        </div>
      </div>

      <div className="app__footer-links_work">
        <h1 className="app__footer-headtext">Working Hours</h1>
        <p className="p__opensans"><IoTimeOutline /> Monday-Friday:</p>
        <p className="p__opensans">08:00 am - 12:00 am</p>
        <p className="p__opensans"><IoTimeOutline /> Saturday-Sunday:</p>
        <p className="p__opensans">07:00 am - 11:00 pm</p>
      </div>
    </div>

    <div className="footer__copyright">
      <p className="p__opensans">2024 Cafe Cassia. All Rights reserved.</p>
    </div>

  </div>
);

export default Footer;
