import React from 'react';

import { SubHeading } from '../../components';
import { images } from '../../constants';
import { IoLocationOutline, IoTimeOutline} from 'react-icons/io5';


const FindUs = () => (
  <div className="app__bg1 app__wrapper section__padding" id="contact">
    <div className="app__wrapper_info">
      <SubHeading title="Contact Us" />
      <h1 className="headtext__cormorant" style={{ marginBottom: '3rem' }}>Find Us</h1>
      <div className="app__wrapper-content">
        <p className="p__opensans"><IoLocationOutline /> Binna Complex, Karen Nairobi, KENYA</p>
        <p className="p__cormorant" style={{ color: '#b4976c', margin: '2rem 0' }}>Opening Hours</p>
        <p className="p__opensans"><IoTimeOutline /> Mon - Fri: 10:00 am - 02:00 am</p>
        <p className="p__opensans"><IoTimeOutline /> Sat - Sun: 10:00 am - 03:00 am</p>
      </div>
      {/* <button type="button" className="custom__button" style={{ marginTop: '2rem' }}>Visit Us</button> */}
    </div>

    <div className="app__wrapper_img">
    <iframe 
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.7285369847496!2d36.73403567454212!3d-1.3391864356853247!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f1b002b951535%3A0x94a2ec1b2fc85121!2sCafe%20Cassia!5e0!3m2!1sen!2ske!4v1726269085505!5m2!1sen!2ske" 
        width="600" 
        height="450" 
        style={{ border: '0' }} 
        allowFullScreen 
        loading="lazy" 
        referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
</div>
  </div>
);

export default FindUs;
