
import { Navbar } from '../components';

import './About.css';
import { images } from '../constants';
import { Footer } from '../container';
import SubHeading from '../components/SubHeading/SubHeading';

function About() {
    return (
        <div>
            <Navbar />
            <div className="section about text-center" aria-labelledby="about-label" id="about">
                <div className="container">
                    <div className="about-content">
                        <SubHeading title="OUR STORY" />
                        <h2 className="headline-1 section-title">Every Flavor Tells a Story</h2>
                        <p className="section-text">
                                Welcome to Café Cassia, where every cup of coffee tells a tale and every dish is crafted with passion. Nestled in the heart of the city, Café Cassia was born from a dream to create a cozy haven where friends, families, and food lovers could gather to enjoy great flavors and even greater moments. Our journey began with a simple belief: that food should not only satisfy hunger, but also nourish the soul.
                        </p>
                        <div className="contact-label">Book Through Call</div>
                        <a href="tel:+254703590989" className="body-1 contact-number hover-underline">+254 (7) 035 90989</a>
                        <a href="#" className="btn btn-primary">
                            <span className="text text-1">Call Us</span>
                            <span className="text text-2" aria-hidden="true">Call Us</span>
                        </a>
                    </div>
                    <figure className="about-banner">
                        <img src={images.Cafe11} width="570" height="570" loading="lazy" alt="about banner" className="w-100" data-parallax-item data-parallax-speed="1" />       
                        <div className="abs-img abs-img-1 has-before" data-parallax-item data-parallax-speed="1.75">
                            <img src={images.aboutimage} width="285" height="285" loading="lazy" alt="" className="w-100" />
                        </div>
                        <div className="abs-img abs-img-2 has-before">
                            {/* <img src={images.badge2} width="133" height="134" loading="lazy" alt="" className='img' /> */}
                        </div>
                    </figure>
                    <img src={images.shape3} width="197" height="194" loading="lazy" alt="" className="shape" />
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default About;
