import React from 'react';
import { Navbar } from '../components';
import { images } from '../constants';
import { Footer } from '../container';
import '../container/Menu/SpecialMenu.css';
// import './Menu.css'; // Ensure you've created a separate CSS for this page

function Menu() {
    return (
        <div>
            <Navbar />
            <div className="app__menu1">
                <h1 className="app__menu-heading1">OUR MENU</h1>
                <div className="app__specialMenu-images">
                    <img src={images.Screen1} alt="Menu Page 1" className="specialMenu__image1" />
                    <img src={images.Screen2} alt="Menu Page 2" className="specialMenu__image2" />
                    <img src={images.Screen3} alt="Menu Page 2" className="specialMenu__image3" />
                    <img src={images.Screen4} alt="Menu Page 2" className="specialMenu__image3" />
                </div>
                <div className="app__specialMenu-images1">
                    <img src={images.Menu1} alt="Menu Page 1" className="specialMenu__image11" />
                    <img src={images.Menu2} alt="Menu Page 2" className="specialMenu__image12" />
                    <img src={images.Menu3} alt="Menu Page 2" className="specialMenu__image13" />
                    <img src={images.Menu4} alt="Menu Page 2" className="specialMenu__image13" />
                    <img src={images.Drink} alt="Menu Page 3" className="specialMenu__image13" />
                    <img src={images.Drink2} alt="Menu Page 4" className="specialMenu__image13" />
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Menu;
